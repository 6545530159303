<template>
  <div id="view__view10" class="min-h">
    <div id="view10__inner" class="view">
      <div class="d-flex top-nadpis align-items-center">
        <div class="hr"></div>
        <h4 style="width: 360px">1. PILIER</h4>
        <div class="hr"></div>
      </div>
      <h1>WINNERS KONCEPT<br />A PYTAGORAS</h1>

      <div class="hr"></div>
      <br />
      <img
        alt="logo"
        class="winners-logo mt-3 fade-in-5"
        src="../assets/img/logosvg.svg"
      />

      <div class="container">
        <div class="row justify-content-center position-relative winners-roww">
          <img src="../assets/img/winners.svg" class="fade-in-2" />
          <img src="../assets/img/winners2.svg" class="fade-in-2" />
          <img src="../assets/img/winners3.svg" class="fade-in-3" />
          <img src="../assets/img/winners4.svg" class="fade-in-4" />
          <img src="../assets/img/winners5.svg" class="fade-in-5" />
        </div>
        <br /><br />
        <div class="hr fade-in-2"></div>
        <br /><br />
        <div class="d-flex flex-column align-items-center fade-in-4">
          <img src="../assets/img/pytagoras.svg" style="width: 294px" />

          <img src="../assets/img/typcomp.png" style="width: 294px" />
        </div>
      </div>
    </div>

    <div class="btn-bottom" style="top: 896px">
      <router-link to="/view11"
        ><button class="btn btn-primary fade-in-3">
          Ďalší krok
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "View8",

  mounted() {
    this.fadeIn();
  },
};
</script>

<style lang="scss">
#view10__inner {
  // background: url("../assets/img/bgt22.png");
  background: url("../assets/img/bgnew.jpg");
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #2b302e;
  background-size: 1600px 900px;
  background-position: center top;
  position: relative;
}

#view__view10 {
  width: 100%;
  height: 1024px;
  display: flex;
  justify-content: center;
  background-color: #2b302e;
  overflow-x: hidden;
  position: relative;

  h1 {
    color: $primary;
    font-size: 51px;
    z-index: 3;
    position: relative;
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 36px;
    font-weight: 500;
  }

  h3 {
    color: #fff;
    font-size: 18px;
    z-index: 3;
    position: relative;
    font-family: $font-regular;
    margin-top: 10px;
    text-transform: none;
  }

  .winners-logo {
    height: 133px;
    width: auto;
    z-index: 2;
    position: relative;
  }
}

.winners-roww {
  margin-top: 50px;
  img {
    width: 107px;
    margin-right: 20px;
  }
}

.hr {
  height: 1px;
  background: $primary;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.top-nadpis {
  width: 800px;
  margin: 0 auto;
  margin-top: 15px;
}
</style>

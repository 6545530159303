<template>
  <div id="view__view3" class="min-h">
    <div id="view3__inner" class="view">
      <img alt="logo" class="winners-logo" src="../assets/img/logo.svg" />
      <!-- <img class="view__main-img" src="../assets/img/view3.jpg" /> -->

      <div class="container">
        <div class="row justify-content-center position-relative">
          <div class="circle__wrap" style="top: 51px">
            <div class="blue__circle fade-in-1">
              <h3>Klient</h3>
              <h4>75J</h4>
            </div>
            <div class="blue__circle fade-in-2">
              <h3>Klient</h3>
              <h4>100J</h4>
            </div>
            <div class="blue__circle fade-in-3">
              <h3>Klient</h3>
              <h4>100J</h4>
            </div>
            <div class="blue__circle fade-in-4">
              <h3>Klient</h3>
              <h4>125J</h4>
            </div>
          </div>
          <div
            class="row justify-content-center flex-column align-items-center fade-in-1"
          >
            <h1>Trainee</h1>
            <h3><span>Vlastná produkcia:</span> 400J</h3>
          </div>

          <div class="right-sec fade-in-2">
            <p>Trainee pomohol 4 vlastným klientom</p>
            <p>Zarobil <span>400J x 2,5€ = 1000€</span></p>
            <hr />
            <p>Trainee postúpil na pozíciu <span>R2</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-bottom fade-in-2">
      <router-link to="/view6"
        ><button class="btn btn-primary">Ďalší krok</button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "View3",

  mounted() {
    this.fadeIn();
  },
};
</script>

<style lang="scss">
#view3__inner {
  // background: url("../assets/img/bgt22.png");
  background: url("../assets/img/bg3.jpg");
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #2d3035;
  background-size: 1600px 900px;
  background-position: center top;
  position: relative;
}

#view__view3 {
  width: 100%;
  height: 1024px;
  display: flex;
  justify-content: center;
  background-color: #2d3035;
  overflow-x: hidden;
  position: relative;

  h1 {
    color: $primary;
    z-index: 3;
    position: relative;
    margin-top: 205px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 50px;
  }

  h3 {
    color: #fff;
    font-size: 18px;
    z-index: 3;
    position: relative;
    font-family: $font-regular;
    margin-top: 10px;
    text-transform: none;

    span {
      font-weight: 500;
    }
  }

  .winners-logo {
    height: 120px;
    width: auto;
    z-index: 2;
    position: relative;
  }
}

.view3__center-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 14px;
  position: absolute;
}

.view__main-img {
  height: 763px;
  position: absolute;
  left: 50%;
  margin-left: -352px;
  top: 0px;
  z-index: 1;
}

.circle__wrap {
  z-index: 1;
  position: absolute;
  left: 108px;
  top: 0;
}

.gold__circle-small {
  background: #ffcd5b;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  z-index: 1;
  margin: 0 auto;
}

.gold-h {
  color: $primary;
  font-size: 14px !important;
  font-family: Gotham-Bold !important;
  margin: 3px 0 0 0;

  span {
    color: white;
  }
}

.gold__circle-empty {
  border-radius: 50%;
  margin-bottom: 22px;
  background: #131820;
  border: 2px solid #ffcd5b;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h4 {
    color: white;
    font-size: 14px !important;

    font-family: Gotham-Bold !important;
    margin: 3px 0 0 0;
  }

  h3 {
    margin: 0 !important;
    color: $primary !important;
    font-size: 26px;
    font-family: $gotham-bold !important;
    position: relative;
    top: 1px;
  }
}

.blue__circle {
  background: #5a8b9f;
  padding: 17px;
  border-radius: 50%;
  width: 48px;
  margin-bottom: 21px;

  h3 {
    font-size: 15px !important;
    color: $primary !important;
    font-family: $font-bold !important;
    margin: 0;
  }

  h4 {
    font-size: 14px !important;
    color: #1a1a1b !important;
    font-family: $font-bold !important;
    margin: 3px 0 0 0;
  }
}

.right-sec {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 142px;
  width: 268px;
  text-align: left;
  color: #a5a5a5;

  span {
    color: white;
  }

  hr {
    border-color: $primary;
    margin: 27px 0;
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    fadeIn: function () {
      const myFade = setTimeout(function fadeInFunc() {
        document
          .querySelectorAll(".fade-in-1, .fade-in-2, .fade-in-3, .fade-in-4, .fade-in-5, .fade-in-6, .fade-in-7, .fade-in-8, .min-h")
          .forEach((x) => x.classList.add("load"));
      }, 500);
      myFade();
    },
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div id="view__view12" class="min-h">
    <div id="view12__inner" class="view">
      <div class="container">
        <div
          class="row d-flex justify-content-center flex-row align-items-center position-relative"
        >
          <div style="position: absolute; top: 568px">
            <div class="d-flex top-nadpis align-items-center">
              <div class="hr"></div>
              <h4
                style="
                  width: 552px;
                  display: block;
                  margin-top: 27px;
                  letter-spacing: 3px;
                "
              >
                PRESTÍŽNE
              </h4>
              <div class="hr"></div>
            </div>
            <h1>VZDELÁVANIE</h1>
            <div class="hr" style="width: 323px"></div>
          </div>
          <img class="hviezda1" src="../assets/img/hviezda1.svg" />

          <p class="text-center hviezda-p">
            Cieľom vzdelania nie sú vedomosti, ale schopnosť konať.<br /><br />Winners
            Academy Vás dokonale pripraví na pôsobenie na finančnom trhu.
            Získate odborné znalosti, informácie pre osobný rozvoj a naši
            skúsení mentori s Vami budú zdieľať praktické skúsenosti a
            zručnosti. Synergický efekt všetkých druhov a spôsobov vzdelávaní je
            základom kariérneho rastu.
          </p>

          <img class="hviezda2" src="../assets/img/hviezda2.svg" />
          <img class="hviezda3" src="../assets/img/hviezda3.svg" />
          <img class="hviezda4" src="../assets/img/hviezda4.svg" />
        </div>
      </div>
    </div>
    <div class="btn-bottom" style="top: 970px">
      <router-link to="/view13"
        ><button class="btn btn-primary">Ďalší krok</button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "View12",

  mounted() {
    this.fadeIn();
  },
};
</script>

<style lang="scss">
#view12__inner {
  background: url("../assets/img/gule.jpg");
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #2d3035;
  background-size: 1500px 831px;
  background-position: calc(50% - 31px) calc(50% - 297px);
  position: relative;

  h1 {
    color: #ffcd5b;
    font-family: "Gotham-Bold";
    font-size: 36px;
    font-weight: 500;
    z-index: 3;
    position: relative;
    margin-top: 0;
    margin-bottom: 25px;
    letter-spacing: 4px;
  }
}

#view__view12 {
  width: 100%;
  height: 1072px;
  display: flex;
  justify-content: center;
  background-color: #2d3035;
  overflow-x: hidden;
  position: relative;
}

.hviezda1 {
  position: absolute;
  top: 106px;
  width: 200px;
}

.hviezda2 {
  position: absolute;
  top: 776px;
  width: 141px;
  margin-left: -615px;
  left: 50%;
  margin-left: -279px;
}

.hviezda3 {
  position: absolute;
  top: 776px;
  width: 141px;
  margin-left: 0;
  left: 50%;
  margin-left: -75px;
}

.hviezda4 {
  position: absolute;
  top: 776px;
  width: 141px;
  margin-left: 130px;
  left: 50%;
}

.hviezda-p {
  max-width: 250px;
  top: 297px;
  position: absolute;
  font-size: 14px;
  color: white;
  line-height: 16px;
}
</style>

<template>
  <div id="view__view8" class="min-h">
    <div id="view8__inner" class="view">
      <div class="hr" style="margin-top: 25px"></div>
      <h1>DÔVODY SPOLUPRÁCE</h1>
      <h4 class="text-primary">S WINNERS GROUP</h4>
      <div class="hr" style="margin-bottom: 15px"></div>
      <img
        alt="logo"
        class="winners-logo fade-in-1"
        src="../assets/img/logo.svg"
      />

      <div class="container">
        <div class="row justify-content-center position-relative fade-in-2">
          <div class="tile">Kvalitnejší život</div>

          <div class="winners-ico fade-in-2">
            <span class="mr-1">VIAC ČASU</span
            ><img
              class="winners-icon fade-in-2"
              src="../assets/img/hodinky.svg"
            />
          </div>
          <div class="winners-ico fade-in-3">
            <img
              class="winners-icon mr-2"
              src="../assets/img/penazenka.svg"
            /><span>VIAC PEŇAZÍ</span>
          </div>

          <div
            class="winners-ico flex-column justify-content-center align-items-center fade-in-4"
          >
            <img class="winners-icon" src="../assets/img/naradie.svg" />
            <div class="mt-2">ZAMESTNANIE</div>
          </div>
          <div
            class="winners-ico flex-column justify-content-center align-items-center fade-in-5"
          >
            <img class="winners-icon" src="../assets/img/kava.svg" />
            <div class="mt-2">PODNIKANIE</div>
          </div>
        </div>

        <div class="row justify-content-center position-relative"></div>
      </div>
    </div>
    <div class="btn-bottom">
      <router-link to="/view7"
        ><button class="btn btn-primary fade-in-1">
          Ďalší krok
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "View8",

  mounted() {
    this.fadeIn();
  },
};
</script>

<style lang="scss">
#view8__inner {
  // background: url("../assets/img/bgt22.png");
  background: url("../assets/img/view8.jpg");
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #0c0a0b;
  background-size: 1600px 900px;
  background-position: center top;
  position: relative;
}

#view__view8 {
  width: 100%;
  height: 1024px;
  display: flex;
  justify-content: center;
  background-color: #0c0a0b;
  overflow-x: hidden;
  position: relative;

  h1 {
    color: $primary;
    font-size: 51px;
    z-index: 3;
    position: relative;
    margin-top: 28px;
    margin-bottom: 0px;
    font-size: 36px;
    font-weight: 500;
  }

  h3 {
    color: #fff;
    font-size: 18px;
    z-index: 3;
    position: relative;
    font-family: $font-regular;
    margin-top: 10px;
    text-transform: none;
  }

  h4 {
    color: $primary;
  }

  .winners-logo {
    height: 133px;
    width: auto;
    z-index: 2;
    position: relative;
    margin-bottom: 10px;
  }
}

.tile {
  background: $primary;
  padding: 10px 25px;
  color: #222;
  border-radius: 5px;
  font-family: $font-bold;
  font-size: 14px;
  text-transform: uppercase;
}

.winners-ico {
  position: absolute;
  left: 50%;

  img {
    width: 60px;
  }

  span,
  div {
    font-family: $font-bold;
    font-size: 14px;
  }

  &:nth-child(2n) {
    margin-left: -280px;
    display: flex;
    align-items: center;
    margin-top: -11px;
  }

  &:nth-child(3n) {
    margin-left: 140px;
    display: flex;
    align-items: center;
    margin-top: -7px;
  }

  &:nth-child(4n) {
    margin-left: -315px;
    top: 330px;
    display: flex;
    align-items: center;
  }

  &:nth-child(5n) {
    margin-left: 200px;
    top: 320px;
    display: flex;
    align-items: center;
  }
}
</style>

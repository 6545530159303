<template>
  <div id="app">
    <!-- <button @click="openFullscreen">fullscreen</button> -->
    <div id="main">
      <div id="fullscreen" class="js-fullscreen">
        <svg
          version="1.1"
          id="svg-fullscreen"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 215.35 215.35"
          style="enable-background: new 0 0 215.35 215.35; width: 25px"
          xml:space="preserve"
        >
          <path
            class="arrow-1"
            d="M7.5,65.088c4.143,0,7.5-3.358,7.5-7.5V25.61l48.305,48.295c1.465,1.464,3.384,2.196,5.303,2.196
  		c1.92,0,3.84-0.732,5.304-2.197c2.929-2.929,2.929-7.678-0.001-10.606L25.604,15.002h31.985c4.142,0,7.5-3.358,7.5-7.5
  		c0-4.142-3.357-7.5-7.5-7.5H7.5c-4.143,0-7.5,3.358-7.5,7.5v50.087C0,61.73,3.357,65.088,7.5,65.088z"
          ></path>
          <path
            class="arrow-2"
            d="M207.85,0.002h-50.086c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h31.979l-48.298,48.301
  		c-2.929,2.929-2.929,7.678,0.001,10.607c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.733,5.304-2.197l48.298-48.301v31.98
  		c0,4.142,3.357,7.5,7.5,7.5c4.143,0,7.5-3.358,7.5-7.5V7.502C215.35,3.359,211.992,0.002,207.85,0.002z"
          ></path>
          <path
            class="arrow-3"
            d="M207.85,150.262c-4.143,0-7.5,3.358-7.5,7.5v31.979l-49.792-49.792c-2.93-2.929-7.678-2.929-10.607,0
  		c-2.929,2.929-2.929,7.678,0,10.606l49.791,49.791h-31.977c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h50.086
  		c4.143,0,7.5-3.358,7.5-7.5v-50.084C215.35,153.62,211.992,150.262,207.85,150.262z"
          ></path>
          <path
            class="arrow-4"
            d="M64.792,139.949L15.005,189.74v-31.978c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v50.086c0,4.142,3.357,7.5,7.5,7.5
  		h50.084c4.142,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H25.611l49.788-49.793c2.929-2.929,2.929-7.678-0.001-10.607
  		C72.471,137.02,67.722,137.02,64.792,139.949z"
          ></path>
        </svg>
      </div>
      <div
        class="center btn-menu"
        :class="{ active: menuActive }"
        @click="menuActive = !menuActive"
      >
        <div></div>
      </div>

      <div id="nav" :class="{ active: menuActive }">
        <router-link to="/view1">Vaša cesta</router-link>
        <router-link to="/view9">Tri piliere</router-link>
        <router-link to="/view10">Winners Koncept</router-link>
        <router-link to="/view11">Pytagoras</router-link>
        <router-link to="/view8">Dôvody spolupráce</router-link>
        <router-link to="/view7">Kariéra</router-link>
        <router-link to="/view2">Plán kariéry</router-link>
        <router-link to="/view3">Trainee</router-link>
        <router-link to="/view6">Reprezentant</router-link>
        <router-link to="/view15">Obch. vedúci</router-link>
        <router-link to="/view14">Oblast. manažér</router-link>
        <router-link to="/view16">Podpora</router-link>
        <router-link to="/view12">Winners Academy</router-link>
        <router-link to="/view13">Autokoncept</router-link>
        <router-link to="/view4">Benefity</router-link>
        <router-link to="/view5">Kariérny štart</router-link>
      </div>
      <!-- <transition name="slide">
        <router-view />
      </transition> -->

      <router-view />

      <OrientationWarning />
    </div>
     <div id="main-false" v-if="notLoggedIn">
      Nemáte prístup k tejto stránke.<br />Prosím, prihláste sa.
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import OrientationWarning from "@/components/OrientationWarning.vue";
import axios from "axios";

export default {
  components: {
    OrientationWarning,
  },

  data: () => ({
    main: true,
    notLoggedIn: "",
    menuActive: false,
  }),

  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.token = params.get("token");
    this.tokenUri = decodeURIComponent(params.get("token"));

    axios
      .get(
        `https://cestakuspechu.winnersgroup.sk/soap.php?token=${this.tokenUri}`,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        console.log(this.tokenUri);

        if (res.data.status == true) {
          console.log("token ok");

          console.log(res);
          this.notLoggedIn = false;
        } else {
          console.log("token false");
          this.notLoggedIn = true;
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("token false");
      });
  },

  mounted() {
    var requestFullscreen = function (ele) {
        ele.requestFullscreen
          ? ele.requestFullscreen()
          : ele.webkitRequestFullscreen
          ? ele.webkitRequestFullscreen()
          : ele.mozRequestFullScreen
          ? ele.mozRequestFullScreen()
          : ele.msRequestFullscreen
          ? ele.msRequestFullscreen()
          : alert("Váš prehliadač nepodporuje režim celej obrazovky");
      },
      exitFullscreen = function () {
        document.exitFullscreen
          ? document.exitFullscreen()
          : document.webkitExitFullscreen
          ? document.webkitExitFullscreen()
          : document.mozCancelFullScreen
          ? document.mozCancelFullScreen()
          : document.msExitFullscreen
          ? document.msExitFullscreen()
          : console.log("Váš prehliadač nepodporuje režim celej obrazovky");
      },
      fsDocButton = document.querySelector(".js-fullscreen");
    fsDocButton.addEventListener("click", function (e) {
      e.preventDefault(),
        this.classList.contains("expanded")
          ? exitFullscreen()
          : requestFullscreen(document.documentElement),
        this.classList.toggle("expanded");
    });
  },

  methods: {
    openFullscreen() {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },

    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  background-color: #262b32;

  height: 100%;
  // overflow: hidden;
}

#nav {
  padding: 0 0 30px 0;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9;

  a {
    font-size: 12px;
    font-family: $font-medium;
    font-weight: 500;
    text-decoration: none;
    color: $primary;
    padding: 27px;
    padding-bottom: 16px;
    border-right: 5px solid $primary;

    &:first-child {
      padding-top: 39px;
    }

    &.router-link-exact-active {
      color: $primary;
    }

    &.router-link-exact-active ~ a {
      border-right: 5px solid #727885;
      color: #727885;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}

#fullscreen {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background: $primary;
  border-radius: 50%;
  padding: 0.6rem;
  cursor: pointer;
  z-index: 23;
  background-image: initial;
  background-color: $primary;
}

.btn-menu {
  right: 31px;
  position: absolute;
  top: 33px;
  z-index: 10;
  display: none;
  cursor: pointer;
  width: 40px;

  @media (max-width: 1500px) {
    display: block;
  }
}

.btn-menu-close {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  display: none;

  @media (max-width: 1500px) {
    display: flex;
  }
}

.min-h {
  min-height: 100vh;
}

#main-false {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  z-index: 999999;
  line-height: 24px;
  font-size: 20px;
}

#nav {
  @media (max-width: 1500px) {
    opacity: 0;
    width: 100%;
    height: 0%;
    transition: 0.5s;

    a {
      padding-bottom: 10px;
    }

    a.router-link-exact-active ~ a {
      color: #ffffffd6;
    }

    &.active {
      opacity: 1;
      width: 100%;
      height: 100%;
      min-height: 100%;
      overflow: visible;
      background: #000000de;
      transition: 0.5s;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      padding-top: 99px;

      a:first-child {
        padding-top: 18px;
      }
    }
  }
}

.center:before,
.center:after,
.center div {
  background: $primary;
  content: "";
  display: block;
  height: 3px;
  border-radius: 3px;
  margin: 7px 0;
  transition: 0.5s;
}
.center.active:before {
  height: 5px;
  transform: translateY(12px) rotate(135deg);
}
.center.active:after {
  height: 5px;
  transform: translateY(-12px) rotate(-135deg);
}
.center.active div {
  height: 5px;
  transform: scale(0);
}
</style>

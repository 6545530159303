<template>
  <div id="view__view7" class="min-h">
    <div id="view7__inner" class="view">
      <div class="hr" style="margin-top: 25px"></div>
      <h1>WINNERS GROUP</h1>
      <h4 class="color-primary">KARIÉRA, KTORÁ VÁM POMÁHA USPIEŤ</h4>
      <div class="hr" style="margin-bottom: 15px"></div>

      <div class="container">
        <div class="row justify-content-center position-relative">
          <div class="kariko-wrap fade-in-1">
            <div class="kariko-circle">
              <img src="../assets/img/ico1.svg" />
            </div>
            <div class="kariko-txt">WINNERS PRÍLEŽITOSŤ</div>
          </div>
          <div class="kariko-wrap fade-in-2">
            <div class="kariko-circle">
              <img src="../assets/img/ico2.svg" />
            </div>
            <div class="kariko-txt">MEDZINÁRODNÉ KNOW-HOW</div>
          </div>
          <div class="kariko-wrap fade-in-3">
            <div class="kariko-circle">
              <img src="../assets/img/ico3.svg" />
            </div>
            <div class="kariko-txt">OSOBNOSTNÝ ROZVOJ</div>
          </div>
          <div class="kariko-wrap fade-in-4">
            <div class="kariko-circle">
              <img src="../assets/img/ico4.svg" />
            </div>
            <div class="kariko-txt">MOTIVAČNÁ KARIÉRA</div>
          </div>
          <div class="kariko-wrap fade-in-5">
            <div class="kariko-circle">
              <img src="../assets/img/ico5.svg" />
            </div>
            <div class="kariko-txt">PRESTÍŽNE VZDELÁVANIE</div>
          </div>
          <div class="kariko-wrap fade-in-6">
            <div class="kariko-circle">
              <img src="../assets/img/ico6.svg" />
            </div>
            <div class="kariko-txt">PARTNERSTVO S WINNERS GROUP</div>
          </div>
          <div class="kariko-wrap kariko-r fade-in-1">
            <div class="kariko-circle">
              <img src="../assets/img/ico7.svg" />
            </div>
            <div class="kariko-txt">BRAND WINNERS GROUP</div>
          </div>
          <div class="kariko-wrap kariko-r fade-in-2">
            <div class="kariko-circle">
              <img src="../assets/img/ico8.svg" />
            </div>
            <div class="kariko-txt">VÝNIMOČNÁ FIREMNÁ KULTÚRA</div>
          </div>
          <div class="kariko-wrap kariko-r fade-in-3">
            <div class="kariko-circle">
              <img src="../assets/img/ico9.svg" />
            </div>
            <div class="kariko-txt">FINTECH TECHNOLÓGIE</div>
          </div>
          <div class="kariko-wrap kariko-r fade-in-4">
            <div class="kariko-circle">
              <img src="../assets/img/ico10.svg" />
            </div>
            <div class="kariko-txt">LEADERSHIP</div>
          </div>
          <div class="kariko-wrap kariko-r fade-in-5">
            <div class="kariko-circle">
              <img src="../assets/img/ico11.svg" />
            </div>
            <div class="kariko-txt">VLASTNÁ WINNERS POBOČKA</div>
          </div>
          <div class="kariko-wrap kariko-r fade-in-6">
            <div class="kariko-circle">
              <img src="../assets/img/ico12.svg" />
            </div>
            <div class="kariko-txt">WINNERS AUTOKONCEPT PREMIUM</div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-bottom fade-in-3">
      <router-link to="/view2"
        ><button class="btn btn-primary">Ďalší krok</button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "View7",

  mounted() {
    this.fadeIn();
  },
};
</script>

<style lang="scss">
#view7__inner {
  // background: url("../assets/img/bgt22.png");
  background: url("../assets/img/view7.jpg");
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #0c0a0b;
  background-size: 1600px 900px;
  background-position: calc(50% - -23px) top;
  position: relative;
}

#view__view7 {
  width: 100%;
  height: 1024px;
  display: flex;
  justify-content: center;
  background-color: #0c0a0b;
  overflow-x: hidden;
  position: relative;

  h1 {
    color: $primary;
    font-size: 50px;
    z-index: 3;
    position: relative;
    margin-top: 20px;
    margin-bottom: 0px;
    font-weight: 500;
  }

  h3 {
    color: #fff;
    font-size: 18px;
    z-index: 3;
    position: relative;
    font-family: $font-regular;
    margin-top: 10px;
    text-transform: none;
  }

  .winners-logo {
    height: 133px;
    width: auto;
    z-index: 2;
    position: relative;
  }
}

.kariko-r {
  .kariko-circle {
    margin-left: 1px;
  }
}

.kariko-circle {
  border: 1px solid;
  border-radius: 50%;
  padding: 8px;
  background: #15161a;
  width: 44px;
  height: 44px;
  position: absolute;
  margin-left: -62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kariko-wrap {
  position: absolute;
  margin-left: -342px;
  display: flex;
  align-items: center;
  top: 130px;
  left: 50%;

  //   &::before {
  //     content: "";
  //     border: 1px solid;
  //     border-radius: 50%;
  //     padding: 8px;
  //     background: #15161a;
  //     width: 40px;
  //     height: 40px;
  //     position: absolute;
  //     margin-left: -10px;
  //   }

  img {
    z-index: 2;
  }

  &:nth-child(2) {
    top: 200px;
  }

  &:nth-child(3) {
    top: 270px;
  }
  &:nth-child(4) {
    top: 340px;
  }
  &:nth-child(5) {
    top: 410px;
  }
  &:nth-child(6) {
    top: 480px;
  }
  &:nth-child(7) {
    margin-right: -416px;
    flex-direction: row-reverse;
    top: 130px;
    left: auto;
    right: 50%;
  }
  &:nth-child(8) {
    margin-right: -416px;
    flex-direction: row-reverse;
    top: 200px;
    left: auto;
    right: 50%;
  }
  &:nth-child(9) {
    margin-right: -416px;
    flex-direction: row-reverse;
    top: 270px;
    left: auto;
    right: 50%;
  }
  &:nth-child(10) {
    margin-right: -416px;
    flex-direction: row-reverse;
    top: 340px;
    left: auto;
    right: 50%;
  }
  &:nth-child(11) {
    margin-right: -416px;
    flex-direction: row-reverse;
    top: 410px;
    left: auto;
    right: 50%;
  }
  &:nth-child(12) {
    margin-right: -416px;
    flex-direction: row-reverse;
    top: 480px;
    left: auto;
    right: 50%;
  }

  &.kariko-r {
    .kariko-txt {
      border-radius: 3px 0 0 3px;
      margin-right: 57px;
    }
  }
}

.kariko-txt {
  padding: 8px 13px 5px 13px;
  border-radius: 0 3px 3px 0;
  background: #ffcd5b;
  color: #222;
  font-family: Gotham-Bold;
  font-size: 13px;
  text-transform: uppercase;
  margin-left: -5px;
}
</style>

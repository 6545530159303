<template>
    <div id="view__view11" class="min-h">
        <div id="view11__inner" :class="{ active: wrappyt }">
            <h1>FINANČNÝ NEPORIADOK</h1>
            <div class="d-flex top-nadpis align-items-center">
                <div class="hr"></div>
                <h4 style="width: 300px; font-size: 16px">
                    CHAOS
                </h4>
                <div class="hr"></div>
            </div>

            <div class="ye-circle ye-secondary-circle__1">
                <h3>10,49</h3>
                <div>
                    <h4>MILIÓNOV</h4>
                    <h5>POČET POISTNÝCH ZMLÚV</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__2">
                <h3>3,31</h3>
                <div>
                    <h4>MILIÓNOV</h4>
                    <h5>POČET ZMLÚV<br/>ŽIVOTNÉHO POISTENIA</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__3">
                <h3>476 258</h3>
                <div>
                    <h4>ZMLÚV</h4>
                    <h5>POČET ÚVEROV<br/>NA BÝVANIE</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__4">
                <h3>465</h3>
                <div>
                    <h4>MILIÓNOV</h4>
                    <h5>EUR ČISTÉ PREDAJE <br/>PODIELOVÝCH FONDOV <br/>V ROKU 2022</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__5">
                <h3>7,18</h3>
                <div>
                    <h4>MILIÓNOV</h4>
                    <h5>POČET ZMLÚV<br/>NEŽIVOTNÉHO POISTENIA</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__6">
                <h3>42,8</h3>
                <div>
                    <h4>MILIARDY EUR</h4>
                    <h5>HYPOTÉKY<br/>FINANCOVANÝ OBJEM</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__7">
                <h3>124</h3>
                <div>
                    <h4>TISÍC ZMLÚV</h4>
                    <h5>POČET NOVÝCH<br/>STAVENBÝCH SPORENÍ<br/>ZA ROK 2022</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__8">
                <h3>2,87</h3>
                <div>
                    <h4>MILIARDY EUR</h4>
                    <h5>LÍZING<br/>FINANCOVANÝ OBJEM</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__9">
                <h3>89,13</h3>
                <div>
                    <h4>MILIARDY EUR</h4>
                    <h5>BANKOVÉ VKLADY</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__10">
                <h3>12,469</h3>
                <div>
                    <h4>MILIARDY EUR</h4>
                    <h5>
                        HODNOTA AKTÍV<br/>PORIELOVÝCH<br/>A INVESTIČNÝCH FONDOV <br/>V ROKU 2022
                    </h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__11">
                <h3>3,11</h3>
                <div>
                    <h4>MILIÓNOV</h4>
                    <h5>POČET ZMLÚV<br/>PZP POISTENIA</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__12">
                <h3>1,8</h3>
                <div>
                    <h4>MILIÓNOV</h4>
                    <h5>POČET SPORITEĽOV<br/>V II. PILIERI</h5>
                </div>
            </div>
            <div class="ye-circle ye-secondary-circle__13">
                <h3>60,9%</h3>
                <div>
                    <h4>PODIEL</h4>
                    <h5>V DLHOPISOVÝCH FONDOCH II. PILIERU <br/>V ROKU 2022</h5>
                </div>
            </div>

            <div class="dotss">
                <div class="small-circle"></div>
                <div
                    class="small-circle"
                    style="top: 593px; margin-left: -275px; padding: 8px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 521px; margin-left: -53px; padding: 5px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 666px; margin-left: -105px; padding: 3px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 704px; margin-left: 155px; padding: 6px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 578px; margin-left: 208px; padding: 11px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 399px; margin-left: 239px; padding: 20px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 192px; margin-left: 458px; padding: 8px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 444px; margin-left: 406px; padding: 6px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 533px; margin-left: 453px; padding: 12px"
                ></div>
                <div
                    class="small-circle"
                    style="top: 755px; margin-left: 535px; padding: 10px"
                ></div>
            </div>
        </div>

        <div id="view11__inner-right" :class="{ active: wrappyt }">
            <h1>NOVÁ ÉRA FINANČNÉHO BIZNISU</h1>
            <div class="d-flex top-nadpis align-items-center">
                <div class="hr"></div>
                <h4 style="width: 600px; font-size: 16px">VAŠA PRÍLEŽITOSŤ</h4>
                <div class="hr"></div>
            </div>
            <div>
                <img
                    src="https://pytagoras.com/assets/img/intro/intro-1.jpg"
                    alt=""
                    class="intro__image__img"
                />

                <div class="dotss">
                    <div
                        class="small-circle"
                        style="margin-left: -281px; padding: 3px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 593px; margin-left: -338px; padding: 3px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 547px; margin-left: -312px; padding: 2px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 411px; margin-left: -331px; padding: 2px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 567px; margin-left: -285px; padding: 2px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 578px; margin-left: -628px; padding: 11px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 399px; margin-left: -643px; padding: 20px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 289px; margin-left: -549px; padding: 8px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 444px; margin-left: -400px; padding: 6px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 533px; margin-left: -482px; padding: 12px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 755px; margin-left: -540px; padding: 10px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 450px; margin-left: -344px; padding: 3px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 370px; margin-left: -344px; padding: 4px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 361px; margin-left: -437px; padding: 9px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 647px; margin-left: -450px; padding: 10px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 474px; margin-left: -254px; padding: 1px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 689px; margin-left: -658px; padding: 22px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 531px; margin-left: -397px; padding: 7px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 552px; margin-left: -254px; padding: 1px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 594px; margin-left: -254px; padding: 1px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 571px; margin-left: -218px; padding: 1px; opacity: 0.7"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 474px; margin-left: -206px; padding: 1px; opacity: 0.6"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 474px; margin-left: -304px; padding: 1px; opacity: 0.6"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 438px; margin-left: -226px; padding: 1px; opacity: 0.6"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 528px; margin-left: -278px; padding: 1px; opacity: 0.6"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 442px; margin-left: -280px; padding: 1px; opacity: 0.6"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 429px; margin-left: -522px; padding: 9px"
                    ></div>
                    <div
                        class="small-circle"
                        style="top: 230px; margin-left: -680px; padding: 28px"
                    ></div>
                    <h2
                        style="
              position: absolute;
              top: 470px;
              left: 50%;
              margin-left: -310px;
            "
                    >
                        BIG DATA
                    </h2>
                </div>
            </div>
        </div>

        <div class="arrow-wrap">
            <div class="arrow-right"></div>
        </div>
        <div class="btn-bottom" style="top: 897px">
            <button
                class="predch"
                :class="{ active: wrappyt }"
                @click="
          wrappyt = false;
          step--;
        "
            >
                Predchádzajúci
            </button>
            <button @click="pytagoras()" class="btn btn-primary fade-in-2">
                Ďalší krok
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'View11',
    data: () => ({
        step: 1,
        wrappyt: false,
    }),

    methods: {
        pytagoras() {
            if (this.step == 2) {
                this.$router.push({name: 'View8'});
            } else {
                this.wrappyt = true;
                this.step++;
            }
        },
    },

    mounted() {
        this.fadeIn();
    },
};
</script>

<style lang="scss">
#view11__inner {
    // background: url("../assets/img/bgt22.png");
    // background: url("../assets/img/fullpage.jpg");
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #181819;
    background-size: 1600px 900px;
    background-position: center top;
    position: relative;
    transition: 1s;

    &.active {
        margin-left: -100%;
    }
}

#view11__inner-right {
    // background: url("../assets/img/bgt22.png");
    // background: url("../assets/img/fullpage.jpg");
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #181819;
    background-size: 1600px 900px;
    background-position: center top;
    position: relative;

    right: 0%;
    margin-right: -100%;
    transition: 1s;

    &.active {
        margin-right: 0%;
        opacity: 1 !important;
    }

    img {
        max-width: 694px;
        margin-top: 31px;
        animation: pytagoras 1s infinite alternate;
    }
}

@keyframes pytagoras {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

.predch {
    margin-left: -133px;
    margin-right: 30px;
    background: #0000;
    border: none;
    color: $primary;
    cursor: pointer;
    display: none;

    &.active {
        display: inline-block;
    }
}

#view__view11 {
    width: 100%;
    height: 1024px;
    display: flex;
    justify-content: center;
    background-color: #181819;
    overflow-x: hidden;
    position: relative;

    h1 {
        font-size: 38px;
        margin-bottom: 0;
        margin-top: 64px;
    }

    h3 {
        color: #222;
    }
}

.small-circle {
    background: #ffcd5b;
    border-radius: 50%;
    position: absolute;
    top: 405px;
    left: 50%;
    margin-left: -249px;
    padding: 5px;
}

.ye-circle {
    padding: 15px;
    border: 2px solid #ffcd5b;
    background: $primary;
    border-radius: 50%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 9;

    div {
        position: absolute;
        bottom: 0;
        margin-bottom: -80px;
        min-width: 220px;
        text-align: left;
        height: 66px;
    }

    h4 {
        font-size: 14px;
        color: $primary;
        margin: 0;
        margin-bottom: 6px;
    }

    h5 {
        font-size: 14px;
        color: gray;
        margin: 0;
    }

    &.ye-secondary-circle__1 {
        left: 50%;
        top: 190px;
        margin-left: -443px;
        width: 69px;
        padding: 4px;
    }

    &.ye-secondary-circle__2 {
        left: 50%;
        top: 366px;
        margin-left: -388px;
        width: 69px;
        padding: 1px;
    }

    &.ye-secondary-circle__3 {
        left: 50%;
        top: 532px;
        margin-left: -451px;
        height: 80px;
    }

    &.ye-secondary-circle__4 {
        left: 50%;
        top: 734px;
        margin-left: -402px;
        width: 69px;
        padding: 6px;
    }

    &.ye-secondary-circle__5 {
        left: 50%;
        top: 259px;
        margin-left: -175px;
        width: 69px;
        padding: 6px;
    }

    &.ye-secondary-circle__6 {
        left: 50%;
        top: 466px;
        margin-left: -205px;
        width: 69px;
        padding: 6px;
    }

    &.ye-secondary-circle__7 {
        left: 50%;
        top: 649px;
        margin-left: -244px;
        width: 69px;
        padding: 6px;
    }

    &.ye-secondary-circle__8 {
        left: 50%;
        top: 207px;
        margin-left: 58px;
        width: 69px;
        padding: 6px;
    }

    &.ye-secondary-circle__9 {
        left: 50%;
        top: 442px;
        margin-left: 18px;
        width: 120px;
        padding: 6px;
        padding-top: 10px;

        h3 {
            font-size: 35px;
        }
    }

    &.ye-secondary-circle__10 {
        left: 50%;
        top: 644px;
        margin-left: -53px;
        width: 120px;
        padding: 6px;
        padding-top: 10px;

        h3 {
            font-size: 35px;
        }
    }

    &.ye-secondary-circle__11 {
        left: 50%;
        top: 220px;
        margin-left: 314px;
        width: 69px;
        padding: 6px;
    }

    &.ye-secondary-circle__12 {
        left: 50%;
        top: 500px;
        margin-left: 285px;
        width: 69px;
        padding: 6px;
    }

    &.ye-secondary-circle__13 {
        left: 50%;
        top: 685px;
        margin-left: 217px;
        width: 69px;
        padding: 6px;
    }
}
</style>

<template>
  <div id="comp__orientation-warning">
    <h2>Obráťte tablet do horizontálnej polohy</h2>
    <div class="img-wrap">
      <img src="@/assets/img/tablet.svg" alt="Tablet" class="tablet-svg" />
      <img src="@/assets/img/ok.svg" class="ok-svg response-img" />
      <img src="@/assets/img/bad.svg" class="bad-svg response-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OrientationWarning",
};
</script>

<style lang="scss">
#comp__orientation-warning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #202329;
  text-align: center;
  font-weight: bold;
  color: $primary;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
  z-index: -1;

  @media (max-width: 913px) {
    transition: opacity 0.3s;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 99;

    .tablet-svg {
      animation: tablet-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-direction: alternate;
    }

    .ok-svg {
      animation: tablet-ok-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-direction: alternate;
    }

    .bad-svg {
      animation: tablet-bad-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-direction: alternate;
    }
  }

  @keyframes tablet-animation {
    0%,
    25% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    75%,
    100% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  @keyframes tablet-ok-animation {
    0%,
    25% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
    }
    75%,
    100% {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
    }
  }
  @keyframes tablet-bad-animation {
    0%,
    25% {
      opacity: 1;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
    }
    75%,
    100% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
    }
  }

  .tablet-svg {
    width: 9rem;
    height: 9rem;
  }

  .response-img {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    width: 3rem;
    height: 3rem;
    margin-top: -6px;
  }

  .img-wrap {
    position: relative;
  }
}
</style>

<template>
    <div id="view__view15" class="min-h">
        <div id="view15__inner" class="view">
            <img alt="logo" class="winners-logo" src="../assets/img/logo.svg"/>
            <div class="container">
                <div class="row justify-content-center position-relative">
                    <div class="circle__wrap" style="top: 19px">
                        <div class="blue__circle fade-in-1">
                            <h3>Klient</h3>
                            <h4>75J</h4>
                        </div>
                        <div class="blue__circle fade-in-2">
                            <h3>Klient</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="blue__circle fade-in-3">
                            <h3>Klient</h3>
                            <h4>125J</h4>
                        </div>
                        <div class="gold__circle-empty fade-in-4">
                            <h3>R2</h3>
                            <h4>75J</h4>
                        </div>
                        <div class="gold__line-dot fade-in-5">
                            <div>
                                <div class="gold__circle-small"></div>
                                <h4 class="mt-2 gold-h">T <span>75J</span></h4>
                            </div>
                            <div class="lines"></div>
                        </div>
                        <div class="gold__circle-empty fade-in-5">
                            <h3>R2</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="gold__line-dot fade-in-5">
                            <div>
                                <div class="gold__circle-small"></div>
                                <h4 class="mt-2 gold-h">T <span>75J</span></h4>
                            </div>
                            <div class="lines"></div>
                        </div>
                        <div class="gold__circle-empty fade-in-5">
                            <h3>R2</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="gold__circle-small fade-in-3"></div>
                        <h4 class="mt-2 gold-h fade-in-3">T <span>100J</span></h4>

                        <!-- <div class="gold__circle-small fade-in-3"></div>
                        <h4 class="mt-2 gold-h fade-in-3">T <span>100J</span></h4> -->
                    </div>
                    <div
                        class="row justify-content-center flex-column align-items-center fade-in-4"
                    >
                        <h1>OBCHODNÝ<br/>VEDÚCI</h1>
                        <h3><span>Vlastná produkcia:</span> 300J</h3>
                        <h3 class="mt-0"><span>Skupinová produkcia:</span> 525J</h3>
                    </div>

                    <div class="right-sec fade-in-1">
                        <p>Obchodný vedúci pomohol 3 vlastným klientom<br/></p>
                        <p style="margin-top: -10px"><span>300J x 7€ = 2100€</span></p>
                        <p>M1 pomohol svojmu tímu<br/></p>
                        <p style="margin-top: -10px"><span>R2 = 275J x 2€ = 550€</span></p>

                        <p><span>T = 250 x 3,5€ = 875€</span></p>
                        <hr/>

                        <p><span>3 525€</span></p>

                        <p>Podmienky postupu na pozíciu M1:<br/></p>
                        <p style="margin-top: -10px">
                            <span>VP 600J, SP 1200J / Q, 3 x R2</span>
                        </p>

                        <br/><br/>
                        <div class="d-flex align-items-center side-icons">
                            <div class="ico-circle">
                                <img src="../assets/img/obch6.svg"/>
                            </div>
                            <span>BONUS<br/>1 000€</span>
                        </div>
                        <!-- <div class="d-flex align-items-center side-icons">
                          <div class="ico-circle">
                            <img src="../assets/img/obch5.svg" />
                          </div>
                          <span>BONUS WINNERS AUTOKONCEPT<br />MERCEDES TRIEDA A</span>
                        </div> -->
                        <div class="d-flex align-items-center side-icons">
                            <div class="ico-circle">
                                <img src="../assets/img/obch8.svg"/>
                            </div>
                            <span>BONUS<br/>WINNERS KANCELÁRIA</span>
                        </div>
                        <div class="d-flex align-items-center side-icons">
                            <div class="ico-circle">
                                <img src="../assets/img/obch7.svg"/>
                            </div>
                            <span>BONUS<br/>ŠTÝLOVÝ MOBIL</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-bottom fade-in-3">
            <router-link to="/view14"
            >
                <button class="btn btn-primary">Ďalší krok</button>
            </router-link
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'View15',

    mounted() {
        this.fadeIn();
    },
};
</script>

<style lang="scss">
#view15__inner {
    // background: url("../assets/img/bgt22.png");
    background: url('../assets/img/typ2.jpg');
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #2d3035;
    background-size: 1600px 900px;
    background-position: center calc(50% - 20px);
    position: relative;
}

#view__view15 {
    width: 100%;
    height: 1024px;
    display: flex;
    justify-content: center;
    background-color: #2d3035;
    overflow-x: hidden;
    position: relative;

    .right-sec {
        position: absolute;
        z-index: 1;
        right: -28px;
        top: 22px;
        width: 300px;
        text-align: left;
        color: #a5a5a5;
    }

    .winners-logo {
        height: 120px;
        width: auto;
        z-index: 2;
        position: relative;
    }

    h1 {
        color: $primary;
        z-index: 3;
        position: relative;
        margin-top: 205px;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 50px;
    }

    h3 {
        color: #fff;
        font-size: 18px;
        z-index: 3;
        position: relative;
        font-family: $font-regular;
        margin-top: 10px;
        text-transform: none;

        span {
            font-weight: 500;
        }
    }
}

.gold__line-dot {
    position: absolute;
    margin-left: -96px;
    margin-top: 26px;

    .lines {
        height: 2px;
        background: $primary;
        position: absolute;
        width: 89px;
        margin-top: -44px;
        z-index: 0;
        margin-left: 8px;
    }
}
</style>

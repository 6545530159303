<template>
    <div id="view__view6" class="min-h">
        <div id="view6__inner" class="view">
            <img alt="logo" class="winners-logo" src="../assets/img/logo.svg"/>
            <!-- <img class="view__main-img" src="../assets/img/view3.jpg" /> -->

            <div class="container">
                <div class="row justify-content-center position-relative">
                    <div class="circle__wrap">
                        <div class="blue__circle fade-in-1">
                            <h3>Klient</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="blue__circle fade-in-2">
                            <h3>Klient</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="blue__circle fade-in-3">
                            <h3>Klient</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="blue__circle fade-in-4">
                            <h3>Klient</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="gold__circle-empty fade-in-5">
                            <h3>R2</h3>
                        </div>
                        <div class="gold__circle-small fade-in-6"></div>
                        <h4 class="mt-2 fade-in-6">T</h4>
                    </div>
                    <div
                        class="row justify-content-center flex-column align-items-center fade-in-3"
                    >
                        <h1>REPREZENTANT</h1>
                        <h3><span>Vlastná produkcia:</span> 400J</h3>
                    </div>

                    <div class="right-sec fade-in-5">
                        <p>Reprezentant R2 pomohol 4 vlastným klientom</p>
                        <p>Zarobil <span>400J x 5€ = 2 000€</span></p>
                        <hr/>
                        <p><span>R2</span> si buduje vlastnú firmu</p>
                        <br/>
                        <p>Podmienky postupu na pozíciu R2: <span>VP 300J</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-bottom fade-in-3">
            <router-link to="/view15"
            >
                <button class="btn btn-primary">Ďalší krok</button>
            </router-link
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'View6',

    mounted() {
        this.fadeIn();
    },
};
</script>

<style lang="scss">
#view6__inner {
    // background: url("../assets/img/bgt22.png");
    background: url('../assets/img/view6.jpg');
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #2d3035;
    background-size: 1600px 900px;
    background-position: center top;
    position: relative;
}

#view__view6 {
    width: 100%;
    height: 1024px;
    display: flex;
    justify-content: center;
    background-color: #2d3035;
    overflow-x: hidden;
    position: relative;

    h1 {
        color: $primary;

        z-index: 3;
        position: relative;
        margin-top: 235px;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 50px;
    }

    h3 {
        color: #fff;
        font-size: 18px;
        z-index: 3;
        position: relative;
        font-family: $font-regular;
        margin-top: 10px;
        text-transform: none;

        span {
            font-weight: 500;
        }
    }

    .winners-logo {
        height: 120px;
        width: auto;
        z-index: 2;
        position: relative;
    }
}
</style>

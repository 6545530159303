<template>
  <div id="view__view9" class="min-h">
    <div id="view9__inner" class="view">
      <div class="d-flex top-nadpis align-items-center">
        <div class="hr"></div>
        <h1 style="width: 1301px; display: block; margin-top: 27px">
          TRI PILIERE
        </h1>
        <div class="hr"></div>
      </div>
      <h1>WINNERS PRÍLEŽITOSTÍ</h1>

      <div class="container">
        <div class="row justify-content-center position-relative winners-grid">
          <div>
            <img
              src="../assets/img/logosvg.svg"
              style="width: 130px"
              class="fade-in-3 logo-spin"
            /><br />
            <img
              src="../assets/img/kriz.svg"
              style="width: 60px; margin-top: 20px"
              class="fade-in-4"
            />
          </div>
          <div>
            <img
              src="../assets/img/raketa.svg"
              style="width: 50px; margin-top: 30px"
              class="fade-in-2 rocket"
            />
          </div>

          <div>
            <img
              src="../assets/img/hlava2.svg"
              style="width: 100px; margin-top: 40px"
              class="fade-in-3"
            />
            <img
              src="../assets/img/kolecko1.svg"
              style="
                width: 36px;
                position: absolute;
                margin-left: -85px;
                margin-top: 72px;
              "
              class="fade-in-3 kolecko-spin"
            />
            <img
              src="../assets/img/kolecko1.svg"
              style="
                width: 30px;
                position: absolute;
                margin-left: -58px;
                margin-top: 53px;
              "
              class="fade-in-3 kolecko-spin2"
            />
          </div>
        </div>
        <div
          class="row justify-content-center position-relative winners-grid fade-in-4"
          style="margin-top: -1px"
        >
          <div>
            <div class="small-dot"></div>
            <h6>1. PILIER</h6>
            <h5>WINNERS KONCEPT<br />& PYTAGORAS</h5>
          </div>
          <div>
            <div class="small-dot"></div>
            <h6>2. PILIER</h6>
            <h5>KARIÉRA</h5>
          </div>
          <div>
            <div class="small-dot"></div>
            <h6>3. PILIER</h6>
            <h5>PODPORA<br />A PROSTREDIE</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-bottom">
      <router-link to="/view10"
        ><button class="btn btn-primary fade-in-4">
          Ďalší krok
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "View9",

  mounted() {
    this.fadeIn();
  },
};
</script>

<style lang="scss">
#view9__inner {
  // background: url("../assets/img/bgt22.png");
  background: url("../assets/img/view9.jpg");
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #0c0a0b;
  background-size: 1920px 1080px;
  background-position: center top;
  position: relative;
}

#view__view9 {
  width: 100%;
  height: 1024px;
  display: flex;
  justify-content: center;
  background-color: #0c0a0b;
  overflow-x: hidden;
  position: relative;

  h1 {
    color: $primary;
    font-family: $chronic-black;
    font-size: 36px;
    font-weight: 500;
    z-index: 3;
    position: relative;
    margin-top: 0;
    margin-bottom: 25px;
    letter-spacing: 4px;
  }

  h3 {
    color: #fff;
    font-size: 18px;
    z-index: 3;
    position: relative;
    font-family: $font-regular;
    margin-top: 10px;
    text-transform: none;
  }

  .winners-logo {
    height: 133px;
    width: auto;
    z-index: 2;
    position: relative;
  }
}

.winners-grid {
  // img {
  //     width: 80px;
  // }

  div {
    padding: 30px;
    border: 1px solid $primary;
    min-width: 204px;

    border-right: none;
    border-bottom: none;

    &:first-child {
      border-left: none;
    }
  }

  .small-dot {
    background: $primary;
    min-width: unset;
    width: 1px;
    height: 1px;
    margin: 0 auto;
    padding: 5px;
    border-radius: 50%;
  }

  h6 {
    color: rgb(201, 201, 201);
    margin-bottom: 0;
  }

  h5 {
    font-family: $gotham-medium;
    letter-spacing: 2px;
  }
}

.rocket {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.kolecko-spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.kolecko-spin2 {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-direction: reverse;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.logo-spin {
  animation-name: animName;
  animation-duration: 7000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 4s;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animName {
  20%,
  100% {
    transform: rotateY(0deg);
  }
  0% {
    transform: rotateY(360deg);
  }
  //   40% {
  //     transform: rotateY(360deg);
  //   }
}
</style>

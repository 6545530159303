<template>
    <div id="view__view13" class="min-h">
        <div id="view13__inner" class="view">
            <div class="container">
                <div
                    class="row d-flex justify-content-center flex-column align-items-center position-relative"
                >
                    <h3 class="text-primary mt-4">WINNERS</h3>
                    <h1>AUTOKONCEPT</h1>
                    <div class="hm-premium">
                        <div class="primary-circle">
                            <h3 class="mb-0 mt-0 plan">PREMIUM</h3>
                        </div>
                        <div class="primary-circle-empty"></div>
                        <div class="primary-circle-empty empty2"></div>
                    </div>

                    <div class="auto-left">
                        <h3 class="text-white">AUDI | MERCEDES BENZ | BMW | PORSCHE</h3>
                        <h3 class="text-primary mb-0 mt-3">WINNERS AUTOKONCEPT PREMIUM</h3>

                        <h2 class="text-primary mt-1">
                            M2 <span>|</span> D1 <span>|</span> D2
                        </h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="container auta-grid">
            <img src="../assets/img/part1.jpg"/>
            <img src="../assets/img/part2.jpg" style="margin-top: 24px"/>

            <div class="auto-left2 mt-4">
                <h3 class="text-primary mb-0 mt-3 text-left">WINNERS AUTOKONCEPT</h3>

                <h2 class="text-primary mt-1 text-left">M2 <span>|</span> M2</h2>
            </div>

            <img src="../assets/img/part3.jpg" style="margin-top: 13px"/>

            <!-- <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/1005.jpg" />
              <img src="../assets/img/cars/103.jpg" />
              <img src="../assets/img/cars/105.jpg" />
              <img src="../assets/img/cars/2.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/1.jpg" />

              <img src="../assets/img/cars/3.jpg" />

              <img src="../assets/img/cars/88.jpg" />
              <img src="../assets/img/cars/94.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/95.jpg" />
              <img src="../assets/img/cars/96.jpg" />
              <img src="../assets/img/cars/97.jpg" />
              <img src="../assets/img/cars/85.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/86.jpg" />
              <img src="../assets/img/cars/87.jpg" />
               <img src="../assets/img/cars/229.jpg" />
              <img src="../assets/img/cars/89.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/91.jpg" />
              <img src="../assets/img/cars/92.jpg" />
              <img src="../assets/img/cars/93.jpg" />
              <img src="../assets/img/cars/98.jpg" />
            </div>

            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/99.jpg" />
              <img src="../assets/img/cars/20.jpg" />
              <img src="../assets/img/cars/newcar.jpg" />
              <img src="../assets/img/cars/109.jpg" />
            </div>

            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/113.jpg" />
              <img src="../assets/img/cars/106.jpg" />
              <img src="../assets/img/cars/107.jpg" />
              <img src="../assets/img/cars/108.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/117.jpg" />
              <img src="../assets/img/cars/110.jpg" />
              <img src="../assets/img/cars/111.jpg" />
              <img src="../assets/img/cars/112.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/121.jpg" />
              <img src="../assets/img/cars/114.jpg" />
              <img src="../assets/img/cars/115.jpg" />
              <img src="../assets/img/cars/116.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/125.jpg" />
              <img src="../assets/img/cars/118.jpg" />
              <img src="../assets/img/cars/119.jpg" />
              <img src="../assets/img/cars/120.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/126.jpg" />
              <img src="../assets/img/cars/122.jpg" />
              <img src="../assets/img/cars/123.jpg" />
              <img src="../assets/img/cars/124.jpg" />
            </div>
            <div class="row justify-content-center position-relative">
              <img src="../assets/img/cars/127.jpg" />
              <img src="../assets/img/cars/128.jpg" />
            </div> -->

            <div class="row justify-content-center flex-column align-items-center" style="margin-top: 20px;">
                <br/><br/>
                <router-link to="/view4"
                >
                    <button id="scrollbtn" class="btn btn-primary">
                        Ďalší krok
                    </button>
                </router-link
                >
                <br/><br/><br/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'View13',

    mounted() {
        this.fadeIn();
    },

    //   methods: {
    //     updateScroll() {
    //       console.log(window.scrollY);
    //       var scrollbtn = document.getElementById("scrollbtn");
    //       if (window.scrollY > 100) {
    //         scrollbtn.classList.add("scrollbtn--acite");
    //       } else {
    //         scrollbtn.classList.remove("scrollbtn--acite");
    //       }
    //     },
    //   },

    //   mounted() {
    //     window.addEventListener("scroll", this.updateScroll);
    //   },
};
</script>

<style lang="scss">
#view13__inner {
    background: url('../assets/img/auto.jpg');
    /* overflow: hidden; */
    height: 1017px;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #060706;
    background-size: 1522px 850px;
    background-position: center calc(50% + -190px);
    position: absolute;
    top: 0;

    h1 {
        color: $primary;
        font-family: $chronic-black;
        font-size: 36px;
        font-weight: 500;
        z-index: 3;
        position: relative;
        margin-top: 0;
        margin-bottom: 25px;
        letter-spacing: 4px;
    }

    h3 {
        font-size: 16px;
        letter-spacing: 5px;
    }

    .primary-circle {
        position: absolute;
        left: 50%;
        top: 333px;
        margin-left: -373px;
        z-index: 3;
        padding: 40px;
        height: 112px;

        h3 {
            color: #333;
            font-size: 16px;
            letter-spacing: 5px;
        }
    }

    .primary-circle-empty {
        position: absolute;
        background-color: transparent;
        left: 50%;
        top: 309px;
        margin-left: -390px;
        padding: 33px;
        height: 120px;
        width: 120px;
        border: 2px solid;

        border-radius: 50%;
        z-index: 1;

        &.empty2 {
            top: 358px;
            margin-left: -401px;
        }
    }
}

#view__view13 {
    width: 100%;
    height: 4000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #060706;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}

.auto-left {
    position: absolute;
    top: 588px;
    margin-left: -340px;
    text-align: left;

    h2 {
        font-size: 38px;
        font-family: $gotham-medium;
        letter-spacing: 2px;

        span {
            font-weight: 300;
            font-family: $font-regular;
        }
    }
}

.auta-grid {
    position: absolute;
    top: 840px;
    left: 50%;
    margin-left: -500px !important;
    max-width: 1000px !important;

    img {
        // width: 25%;
        // margin: 5px;
        // border-radius: 8px;
        // width: 240px;
        width: 100%;
    }
}

.scrollbtn {
    transition: 1s;

    &.scrollbtn--acite {
        position: fixed;
        bottom: 15px;
    }
}

.auto-left2 {
    h2 {
        font-size: 38px;
        font-family: $gotham-medium;
        letter-spacing: 2px;

        span {
            font-weight: 300;
            font-family: $font-regular;
        }
    }
}

.text-left {
    text-align: left !important;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import View1 from "../views/View1.vue";
import View2 from "../views/View2.vue";
import View3 from "../views/View3.vue";
import View4 from "../views/View4.vue";
import View5 from "../views/View5.vue";
import View6 from "../views/View6.vue";
import View7 from "../views/View7.vue";
import View8 from "../views/View8.vue";
import View9 from "../views/View9.vue";
import View10 from "../views/View10.vue";
import View11 from "../views/View11.vue";
import View12 from "../views/View12.vue";
import View13 from "../views/View13.vue";
import View14 from "../views/View14.vue";
import View15 from "../views/View15.vue";
import View16 from "../views/View16.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/view1",
    name: "View1",
    component: View1,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import("../views/View1.vue"),
  },
  {
    path: "/view2",
    name: "View2",
    component: View2,
    // component: () => import("../views/View2.vue"),
  },
  {
    path: "/view3",
    name: "View3",
    component: View3,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view4",
    name: "View4",
    component: View4,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view5",
    name: "View5",
    component: View5,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view6",
    name: "View6",
    component: View6,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view7",
    name: "View7",
    component: View7,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view8",
    name: "View8",
    component: View8,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view9",
    name: "View9",
    component: View9,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view10",
    name: "View10",
    component: View10,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view11",
    name: "View11",
    component: View11,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view12",
    name: "View12",
    component: View12,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view13",
    name: "View13",
    component: View13,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view14",
    name: "View14",
    component: View14,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view15",
    name: "View15",
    component: View15,
    // component: () => import("../views/View3.vue"),
  },
  {
    path: "/view16",
    name: "View16",
    component: View16,
    // component: () => import("../views/View3.vue"),
  },
  { path: "/", redirect: "/view1" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;

<template>
    <div id="view__view16" class="min-h">
        <div id="view16__inner" class="view">
            <div class="d-flex top-nadpis align-items-center">
                <div class="hr"></div>
                <h4 style="width: 360px">3. PILIER</h4>
                <div class="hr"></div>
            </div>
            <h1>PODPORA<br/>A PROSTREDIE</h1>

            <div class="hr"></div>
            <br/>
            <br/>
            <div class="container">
                <div
                    class="row justify-content-center position-relative flex-column align-items-center"
                >
                    <h3>NOVÁ ÉRA FINANČNÉHO BIZNISU</h3>
                    <img class="pc2" src="../assets/img/pc2.png"/>
                    <h3 class="mt-3 mb-4">NÁSTROJE NOVEJ GENERÁCIE</h3>
                    <img class="pc1" src="../assets/img/pc1.png"/>
                </div>
            </div>
        </div>
        <div class="btn-bottom">
            <router-link to="/view12"
            >
                <button class="btn btn-primary">Ďalší krok</button>
            </router-link
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'View16',

    mounted() {
        this.fadeIn();
    },
};
</script>

<style lang="scss">
#view16__inner {
    // background: url("../assets/img/bgt22.png");
    background: url('../assets/img/bgnew.jpg');
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #2b302e;
    background-size: 1600px 900px;
    background-position: center top;
    position: relative;
}

#view__view16 {
    width: 100%;
    height: 1026px;
    display: flex;
    justify-content: center;
    background-color: #2b302e;
    overflow-x: hidden;
    position: relative;
    overflow: hidden;

    .btn-bottom {
        top: 937px;
        padding-bottom: 97px;
    }

    h3 {
        font-size: 16px;
        letter-spacing: 5px;
    }

    .right-sec {
        position: absolute;
        z-index: 1;
        right: -28px;
        top: 142px;
        width: 300px;
        text-align: left;
        color: #a5a5a5;
    }

    .winners-logo {
        height: 133px;
        width: auto;
        z-index: 2;
        position: relative;
    }

    h1 {
        color: $primary;
        z-index: 3;
        position: relative;
        margin-top: -4px;
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 36px;
    }

    h3 {
        color: $primary;
        font-size: 18px;
        z-index: 3;
        position: relative;
        font-family: $font-regular;
        margin-top: 10px;
        text-transform: none;
    }
}

.pc1 {
    width: 291px;
    margin: 0 auto;
}

.pc2 {
    width: 527px;
    margin: 0 auto;
}
</style>

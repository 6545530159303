<template>
  <div id="view__view4" class="min-h">
    <div id="view4__inner" class="view">
      <img alt="logo" class="winners-logo" src="../assets/img/logo.svg" />

      <div class="container">
        <div
          class="row circle-row justify-content-center flex-column align-items-center"
        >
          <div class="primary-circle">
            <h3 class="mb-0">WINNERS</h3>
            <h1 class="mt-1">Benefity</h1>
          </div>
          <div class="circle circle0"></div>
          <div class="circle circle1"></div>
          <div class="circle circle2"></div>
          <div class="circle circle3"></div>
          <div class="circle circle4"></div>
          <div class="circle circle5"></div>
          <div class="circle circle6"></div>
          <div class="circle circle7"></div>

          <div class="secondary-circle secondary-circle__1">
            <img src="../assets/img/benefit1.jpg" />
          </div>
          <div class="secondary-circle secondary-circle__2">
            <img src="../assets/img/benefit2.jpg" />
          </div>
          <div class="secondary-circle secondary-circle__3">
            <img src="../assets/img/benefit3.jpg" />
          </div>
          <div class="secondary-circle secondary-circle__4">
            <img src="../assets/img/benefit4.jpg" />
          </div>

          <div id="arc-text1" class="arch-text arch1">
            Luxusné autá winners autokoncept premium
          </div>
          <div id="arc-text2" class="arch-text arch2">Štýlový notebook</div>
          <div id="arc-text3" class="arch-text arch3">Cesta okolo sveta</div>
          <div id="arc-text4" class="arch-text arch4">Vlastný WINNERS POINT</div>
          <div id="arc-text5" class="arch-text arch5">Sociálny program</div>
          <div id="arc-text6" class="arch-text arch6">
            Prestížne vzdelávanie
          </div>

          <div class="arch-circle1"></div>
          <div class="arch-circle2"></div>
          <div class="arch-circle3"></div>
          <div class="arch-circle4"></div>
          <div class="arch-circle5"></div>
          <div class="arch-circle6"></div>
        </div>
      </div>
    </div>
    <div class="btn-bottom">
      <router-link to="/view5"
        ><button class="btn btn-primary">Ďalší krok</button></router-link
      >
    </div>
  </div>
</template>

<script>
// import VueArcText from "vue-arc-text";
import CircleType from "circletype";

export default {
  name: "View4",
  // components: { VueArcText },
  data() {
    return {};
  },

  mounted() {
    const circleType1 = new CircleType(document.getElementById("arc-text1"));
    circleType1.dir(-1).radius(295);

    const circleType2 = new CircleType(document.getElementById("arc-text2"));
    circleType2.dir(1).radius(295);

    const circleType3 = new CircleType(document.getElementById("arc-text3"));
    circleType3.dir(1).radius(295);

    const circleType4 = new CircleType(document.getElementById("arc-text4"));
    circleType4.dir(-1).radius(295);

    const circleType5 = new CircleType(document.getElementById("arc-text5"));
    circleType5.dir(-1).radius(295);

    const circleType6 = new CircleType(document.getElementById("arc-text6"));
    circleType6.dir(-1).radius(355);

    this.fadeIn();
  },
};
</script>

<style lang="scss">
#view4__inner {
  // background: url("../assets/img/bgt22.png");
  // background: url("../assets/img/view3bg.png");
  overflow: hidden;
  height: 100%;
  width: 100%;
  /* background-repeat: no-repeat; */
  background-size: contain;
  background-position: -217px;
  position: relative;
  overflow: initial;
  //   @media (min-width: 1581px) {
  //     width: 100%;
  //     height: 100vh;
  //   }
}

#view__view4 {
  width: 100%;
  height: 1024px;
  display: flex;
  justify-content: center;
  background-color: #222931;
  overflow-x: hidden;
  position: relative;

  .primary-circle {
    height: 277px;
  }

  h1 {
    color: #171e28;
    font-size: 48px;
    z-index: 3;
    position: relative;
    margin-bottom: 0px;
  }

  h3 {
    color: #171e28;
    font-size: 21px;
    z-index: 3;
    position: relative;
    font-family: $font-regular;
    margin-top: 10px;
    text-transform: none;
  }

  .winners-logo {
    height: 89px;
    width: auto;
    z-index: 2;
    position: relative;
  }
}

.btn-bottom {
  position: absolute;
  z-index: 2;
  top: 770px;
  left: 50%;
  margin-left: -120px;
}

.blue__circle {
  background: #5a8b9f;
  padding: 17px;
  border-radius: 50%;
  width: 48px;
  margin-bottom: 21px;

  h3 {
    font-size: 15px !important;
    color: $primary !important;
    font-family: $font-bold !important;
    margin: 0;
  }

  h4 {
    font-size: 14px !important;
    color: #1a1a1b !important;
    font-family: $font-bold !important;
    margin: 3px 0 0 0;
  }
}

.circle-row {
  height: 580px;
}

.primary-circle {
  padding: 49px;
  background: $primary;
  border-radius: 50%;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.secondary-circle {
  padding: 15px;
  border: 2px solid #ffcd5b;
  border-radius: 50%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9;

  &.secondary-circle__1 {
    left: 50%;
    top: 9px;
    margin-left: -484px;
  }

  &.secondary-circle__2 {
    left: 50%;
    top: 460px;
    margin-left: -556px;
  }

  &.secondary-circle__3 {
    left: 50%;
    top: 175px;
    margin-left: 256px;
  }

  &.secondary-circle__4 {
    left: 50%;
    top: 551px;
    margin-left: 321px;
  }

  img {
    border-radius: 50%;
  }
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 1px solid #ffcd5b4a;
}

.circle0 {
  padding: 189px;
}

.circle1 {
  padding: 231px;
}

.circle2 {
  padding: 273px;
}

.circle3 {
  padding: 317px;
}

.circle4 {
  padding: 364px;
}

.circle5 {
  padding: 413px;
}

.circle6 {
  padding: 463px;
}

.circle7 {
  padding: 513px;
}

.arch-text {
  text-transform: uppercase;
  font-size: 9px;
  position: relative;

  &.arch1 {
    position: absolute;
    left: 50%;
    margin-left: -191px;
    top: 507px;
    transform: rotate(48deg);
  }

  &.arch2 {
    position: absolute;
    left: 50%;
    margin-left: -252px;
    top: 153px;
    transform: rotate(311deg);
  }

  &.arch3 {
    position: absolute;
    left: 50%;
    margin-left: 196px;
    top: 102px;
    transform: rotate(36deg);
  }

  &.arch4 {
    position: absolute;
    left: 50%;
    margin-left: 310px;
    top: 492px;
    transform: rotate(293deg);
  }

  &.arch5 {
    position: absolute;
    left: 50%;
    margin-left: 137px;
    top: 676px;
    transform: rotate(336deg);
  }

  &.arch6 {
    position: absolute;
    left: 50%;
    margin-left: -259px;
    top: 715px;
    transform: rotate(37deg);
  }
}

.arch-circle1 {
  position: absolute;
  left: 50%;
  margin-left: -374px;
  top: 660px;
  border-radius: 50%;
  padding: 9px;
  background: $primary;
}

.arch-circle2 {
  position: absolute;
  left: 50%;
  margin-left: -302px;
  top: 254px;
  border-radius: 50%;
  padding: 9px;
  background: $primary;
}

.arch-circle3 {
  position: absolute;
  left: 50%;
  margin-left: 99px;
  top: 76px;
  border-radius: 50%;
  padding: 9px;
  background: $primary;
}

.arch-circle4 {
  position: absolute;
  left: 50%;
  margin-left: 269px;
  top: 611px;
  border-radius: 50%;
  padding: 9px;
  background: $primary;
}

.arch-circle5 {
  position: absolute;
  left: 50%;
  margin-left: 52px;
  top: 732px;
  border-radius: 50%;
  padding: 9px;
  background: $primary;
}

.arch-circle6 {
  position: absolute;
  left: 50%;
  margin-left: -326px;
  top: 349px;
  border-radius: 50%;
  padding: 9px;
  background: $primary;
}
</style>

<template>
    <div id="view__view5" class="min-h">
        <div id="view4__inner" class="view">
            <div class="logo-bg">
                <img alt="logo" class="winners-logo" src="../assets/img/logo.svg"/>
            </div>

            <div class="kariera-container">
                <div
                    class="row kariera-row justify-content-center flex-column align-items-center"
                >
                    <div class="karierny-start">
                        <button class="btn btn-primary-rounded mb-2 kariera-upper">
                            WINNERS ACADEMY DIRECTOR
                        </button>
                        <img src="../assets/img/arrow2.svg"/>
                        <button class="btn btn-primary-outline mt-2 mb-2 kariera-upper">
                            WINNERS ACADEMY MANAGER
                        </button>
                        <img src="../assets/img/arrow2.svg" class="kariera-upper"/>
                        <h4 class="kariera-upper" style="color:#FFCD5B;">ZLATÝ KĽÚČ</h4>
                        <p class="kariera-upper">Oficiálny štart spolupráce</p>
                        <img src="../assets/img/arrow2.svg" class="kariera-upper"/>
                        <h4 class="kariera-upper">LICENCIA NBS</h4>
                        <img src="../assets/img/arrow2.svg" class="kariera-upper"/>
                        <h4>TRÉNINGOVÉ PONDELKY</h4>
                        <img src="../assets/img/arrow2.svg"/>
                        <h4>PROFI SOBOTA</h4>
                        <img src="../assets/img/arrow2.svg"/>
                    </div>

                    <div class="kariera-level" style="width: 211px">
                        <h4>LEVEL 6</h4>
                        <h2>ÚSPEŠNÝ PREDAJ</h2>
                    </div>
                    <img src="../assets/img/arrow2.svg"/>
                    <div class="kariera-level" style="width: 173px">
                        <h4>LEVEL 5</h4>
                        <h2>PYTAGORAS 2</h2>
                    </div>
                    <img src="../assets/img/arrow2.svg"/>
                    <div class="kariera-level">
                        <h4>LEVEL 4</h4>
                        <h2>FINANČNÉ PLÁNOVANIE</h2>
                    </div>
                    <img src="../assets/img/arrow2.svg"/>
                    <div class="kariera-level">
                        <h4>LEVEL 3</h4>
                        <h2>WINNERS KONCEPT</h2>
                    </div>
                    <img src="../assets/img/arrow2.svg"/>
                    <div class="kariera-level">
                        <h4>LEVEL 2</h4>
                        <h2>WINNERS PLUS</h2>
                    </div>
                    <img src="../assets/img/arrow2.svg"/>
                    <div class="kariera-level">
                        <h4>LEVEL 1</h4>
                        <h2>PYTAGORAS</h2>
                    </div>
                    <img src="../assets/img/arrow2.svg"/>
                    <div class="kariera-level">
                        <h1 class="mb-2">Kariérny štart</h1>
                        <img src="../assets/img/hands.svg"/>
                        <h3 class="mt-2">399€</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-bottom" style="top: 933px">
            <button @click="karieraScroll()" class="btn btn-primary w-100">
                Ďalší krok
            </button>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
    name: 'View5',
    //   components: {
    //     HelloWorld,
    //   },
    data: () => ({
        karieraScrolled: false,
    }),

    mounted() {
        this.fadeIn();
    },

    methods: {
        karieraScroll() {
            let row = document.querySelector('.kariera-row');
            // let circle = document.querySelectorAll(".kariera-level");
            // let upper = document.querySelectorAll(".karierny-start");
            row.classList.add('scroll-down');
            // circle.forEach((x) => x.classList.add("d-none"));
            // upper.forEach((x) => x.classList.add("active"));

            if (this.karieraScrolled == true) {
                this.$router.push({name: 'View1'});
            }
            this.karieraScrolled = true;
        },
    },
};
</script>

<style lang="scss">
#view5__inner {
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-size: contain;
    background-position: -217px;
    position: relative;
    overflow: initial;
}

#view__view5 {
    width: 100%;
    height: 1024px;
    display: flex;
    justify-content: center;
    background-color: #222931;
    overflow-x: hidden;
    position: relative;

    .container {
        max-width: 1116px;
    }

    h1 {
        color: $primary;
        font-size: 32px;
        z-index: 3;
        position: relative;
        margin-bottom: 0px;
        text-transform: uppercase;
        font-family: $gotham-bold;
    }

    h3 {
        color: $primary;
        font-size: 21px;
        z-index: 3;
        position: relative;
        font-family: $font-medium;
        margin-top: 10px;
        text-transform: none;
    }

    .winners-logo {
        height: 89px;
        width: auto;
        z-index: 2;
        position: relative;
    }
}

.kariera-level {
    // position: absolute;
    // left: 50%;
    // margin-left: -8px;
    // text-align: left;

    h4 {
        margin-bottom: 5px;
        font-size: 15px;
        color: #fff;
        font-family: $font-medium;
    }

    h2 {
        font-size: 18px;
        margin-top: 0;
        font-family: $font-black;
        font-weight: 500;
    }
}

.kariera-circle {
    position: absolute;
    border-radius: 50%;
    padding: 9px;
    background: $primary;
    transition: 2s;

    &.kariera-txt1 {
        left: 50%;
        margin-left: -557px;
        top: 110px;
    }

    &.kariera-txt2 {
        left: 50%;
        margin-left: -357px;
        top: 110px;
    }

    &.kariera-txt3 {
        right: 50%;
        margin-right: -249px;
        top: 110px;
    }

    &.kariera-txt4 {
        right: 50%;
        margin-right: -433px;
        top: 110px;
    }

    &.kariera-txt5 {
        left: 50%;
        margin-left: -356px;
        top: -28px;
    }

    &.kariera-txt6 {
        right: 50%;
        margin-right: -249px;
        top: -28px;
    }
}

.kariera-container {
    position: absolute;
    height: 898px;
    overflow: hidden;
    left: 50%;
    margin-left: -173px !important;
}

.kariera-row {
    margin-top: 5px;
    position: relative;
    transition: 2s;

    margin-top: -611px;

    &.scroll-down {
        margin-top: 98px;
    }
}

.kariera-upper {
    transition: 2s;
}

.karierny-start {
    p {
        color: white;
        font-size: 16px;
        margin-top: -10px;
    }

    h4 {
        margin-bottom: 18px;
        margin-top: 18px;
        font-size: 18px;
        color: #fff;
        font-family: $font-medium;
    }
}

.logo-bg {
    background: #222931;
    position: relative;
    z-index: 1;
}
</style>

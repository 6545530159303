<template>
    <div id="view__view1" class="min-h">
        <div id="view1__inner" class="view ">
            <img
                alt="logo"
                src="../assets/img/logo.svg"
                class="fade-in mt-1"
                style="width: 110px"
            />
            <div class="view1__center-box">
                <img
                    alt="logo"
                    src="../assets/img/earth.png"
                    class="fade-in earth"
                    style="max-width: 300px"
                />
                <h1 class="mb-2 fade-in-1">Winners</h1>
                <h1 class="mt-0 fade-in-2">Príležitosť</h1>

                <router-link to="/view9"
                >
                    <button class="btn btn-primary fade-in-3">
                        Začať príležitosť
                    </button>
                </router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
    name: 'View1',
    //   components: {
    //     HelloWorld,
    //   },

    mounted() {
        this.fadeIn();
    },
};
</script>

<style lang="scss">
#view1__inner {
    background: url('../assets/img/first.png');
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #2d3744;
    // background-size: 1600px 900px;W
    background-size: cover;
    background-position: center;
    position: relative;

    W
    h1 {
        font-weight: 500;
    }
}

#view__view1 {
    width: 100%;
    height: 1024px;
    display: flex;
    justify-content: center;
    background-color: #2d3744;
}

.view1__center-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    position: absolute;
    margin-top: -52px;
}

.min-h {
    opacity: 0 !important;
    transition: opacity .5s ease-in;
}

.fade-in-1 {
    opacity: 0 !important;
    transition: opacity 1s ease-in;
}

.fade-in-2 {
    opacity: 0 !important;
    transition: opacity 2s ease-in;
}

.fade-in-3 {
    opacity: 0 !important;
    transition: opacity 3s ease-in;
}

.fade-in-4 {
    opacity: 0 !important;
    transition: opacity 4s ease-in;
}

.fade-in-5 {
    opacity: 0 !important;
    transition: opacity 5s ease-in;
}

.fade-in-6 {
    opacity: 0 !important;
    transition: opacity 6s ease-in;
}

.fade-in-7 {
    opacity: 0 !important;
    transition: opacity 7s ease-in;
}

.fade-in-1,
.fade-in-2,
.fade-in-3,
.fade-in-4,
.fade-in-5,
.fade-in-6,
.fade-in-7,
.min-h {
    &.load {
        opacity: 1 !important;
    }
}

.earth {
    animation: earth 20s infinite linear;
}

@keyframes earth {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

<template>
    <div id="view__view14" class="min-h">
        <div id="view14__inner" class="view">
            <img alt="logo" class="winners-logo" src="../assets/img/logo.svg" style="height: 120px;"/>
            <div class="container">
                <div class="row justify-content-center position-relative">
                    <div class="circle__wrap" style="top: 45px">
                        <div class="blue__circle fade-in-1">
                            <h3>Klient</h3>
                            <h4>75J</h4>
                        </div>
                        <div class="blue__circle fade-in-2">
                            <h3>Klient</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="blue__circle fade-in-3">
                            <h3>Klient</h3>
                            <h4>125J</h4>
                        </div>
                        <div
                            class="gold__circle-empty fade-in-4"
                            style="background: #ffcd5b"
                        >
                            <h3 style="color: #222 !important">M1</h3>
                            <h4 style="color: #222">SP: 500J</h4>
                        </div>
                        <div class="gold__line-dot fade-in-5">
                            <div>
                                <div class="gold__circle-small"></div>
                                <h4 class="mt-2 gold-h">T <span>75J</span></h4>
                            </div>
                            <div class="lines"></div>
                        </div>
                        <div class="gold__circle-empty fade-in-4">
                            <h3>R2</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="gold__line-dot fade-in-5">
                            <div>
                                <div class="gold__circle-small"></div>
                                <h4 class="mt-2 gold-h">T <span>75J</span></h4>
                            </div>
                            <div class="lines"></div>
                        </div>

                        <div class="gold__line-dot fade-in-4" style="margin-left: 133px">
                            <div>
                                <div
                                    class="lines"
                                    style="margin-top: 11px; margin-left: -56px; width: 67px"
                                ></div>
                                <div class="gold__circle-small"></div>
                                <h4 class="mt-2 gold-h" style="width: 45px">
                                    T <span>75J</span>
                                </h4>
                            </div>
                        </div>

                        <div class="gold__circle-empty fade-in-3">
                            <h3>R2</h3>
                            <h4>100J</h4>
                        </div>
                        <div class="gold__circle-small fade-in-3"></div>
                        <h4 class="mt-2 gold-h fade-in-3">T <span>75J</span></h4>

                        <div class="gold__circle-small fade-in-3 mt-2"></div>
                        <h4 class="mt-2 gold-h fade-in-3">T <span>100J</span></h4>
                    </div>
                    <div
                        class="row justify-content-center flex-column align-items-center fade-in-2"
                    >
                        <h1>OBLASTNÝ<br/>MANAŽÉR</h1>
                        <h3><span>Vlastná produkcia:</span> 300J</h3>
                        <h3 class="mt-0"><span>Skupinová produkcia:</span> 1100J</h3>
                    </div>

                    <div class="right-sec fade-in-1">
                        <p>Oblastný manažér pomohol 3 vlastným klientom<br/></p>
                        <p style="margin-top: -10px"><span>300J x 8,5€ = 2 550€</span></p>
                        <p>M2 pomohol svojmu tímu<br/></p>
                        <p style="margin-top: -10px">
                            <span>M1 = 500J x 1,5€ = 750€</span>
                        </p>
                        <p><span>R2 = 200J x 3,5€ = 700€</span></p>
                        <p><span>T = 400J x 5€ = 2 000€</span></p>
                        <hr/>

                        <p><span>6 000€</span></p>

                        <p>Podmienky postupu na pozíciu M2:<br/></p>
                        <p style="margin-top: -10px">
                            <span>VP 800J, SP 3800J / Q, 6 x R2</span>
                        </p>
                        <br/><br/>
                        <div class="d-flex align-items-center side-icons">
                            <div class="ico-circle">
                                <img src="../assets/img/obch2.svg"/>
                            </div>
                            <span>BONUS<br/>WINNERS POBOČKA</span>
                        </div>
                        <!-- <div class="d-flex align-items-center side-icons">
                          <div class="ico-circle">
                            <img src="../assets/img/obch1.svg" />
                          </div>
                          <span>BONUS<br />WINNERS AUTOKONCEPT PREMIUM</span>
                        </div> -->
                        <div class="d-flex align-items-center side-icons">
                            <div class="ico-circle">
                                <img src="../assets/img/auticko.png" style="width: 36px;"/>
                            </div>
                            <span>BONUS<br/>WINNERS AUTOKONCEPT PREMIUM</span>
                        </div>
                        <div class="d-flex align-items-center side-icons">
                            <div class="ico-circle">
                                <img src="../assets/img/obch4.svg"/>
                            </div>
                            <span>BONUS<br/>SOCIÁLNY PROGRAM</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-bottom fade-in-1">
            <router-link to="/view16"
            >
                <button class="btn btn-primary">Ďalší krok</button>
            </router-link
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'View14',

    mounted() {
        this.fadeIn();
    },
};
</script>

<style lang="scss">
#view14__inner {
    // background: url("../assets/img/bgt22.png");
    background: url('../assets/img/typ1.jpg');
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #2d3035;
    background-size: 1600px 900px;
    background-position: center calc(50% - 20px);
    position: relative;
}

#view__view14 {
    width: 100%;
    height: 1024px;
    display: flex;
    justify-content: center;
    background-color: #2d3035;
    overflow-x: hidden;
    position: relative;

    .right-sec {
        position: absolute;
        z-index: 1;
        right: -28px;
        top: 52px;
        width: 300px;
        text-align: left;
        color: #a5a5a5;
    }

    .winners-logo {
        height: 133px;
        width: auto;
        z-index: 2;
        position: relative;
    }

    h1 {
        color: $primary;
        z-index: 3;
        position: relative;
        margin-top: 205px;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 50px;
    }

    h3 {
        color: #fff;
        font-size: 18px;
        z-index: 3;
        position: relative;
        font-family: $font-regular;
        margin-top: 10px;
        text-transform: none;

        span {
            font-weight: 500;
        }
    }
}

.side-icons {
    font-size: 14px;
    margin-top: 15px;

    img {
        width: 30px;
    }

    span {
        font-weight: 700;
    }
}

.ico-circle {
    border: 1px solid;
    border-radius: 50%;
    background: #ffcd5b;
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
</style>

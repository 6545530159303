<template>
    <div id="view__view2" class="min-h">
        <div id="view2__inner" class="view">
            <div class="container" id="schody1">
                <div class="row row-schody justify-space-between">
                    <!-- schod1 -->
                    <div class="view2__schod__wrap" style="margin-top: 450px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div id="schod1H" class="schodh invisible"><h3>T</h3></div>
                            <div class="view2__schod__yellow-circle"></div>
                            <div
                                id="schod1L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod1" class="view2__schod"></div>
                        <div
                            id="schodT1"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>TRAINEE</h4>

                            <p class="mt-2 mb-1">Príjem</p>
                            <h4 class="mt-0">500€ - 1.500€</h4>
                        </div>
                    </div>

                    <!-- schod2 -->
                    <div class="view2__schod__wrap" style="margin-top: 300px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div id="schod2H" class="schodh invisible">
                                <h3>R2</h3>
                                <h6>300j</h6>
                                <p>vlastnej produkcie</p>
                            </div>
                            <div class="view2__schod__yellow-circle"></div>
                            <div
                                id="schod2L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod2" class="view2__schod"></div>
                        <div
                            id="schodT2"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>REPREZENTANT</h4>
                            <p class="mt-2 mb-1">Príjem</p>
                            <h4 class="mt-0">700€ - 2.500€</h4>
                        </div>
                    </div>

                    <!-- schod3 -->
                    <div class="view2__schod__wrap" style="margin-top: 150px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div id="schod3H" class="schodh invisible">
                                <h3>R3</h3>
                                <h6>1500j</h6>
                                <p>vlastnej produkcie</p>
                            </div>
                            <div class="view2__schod__yellow-circle"></div>
                            <div
                                id="schod3L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod3" class="view2__schod"></div>
                        <div
                            id="schodT3"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>REPREZENTANT SENIOR</h4>
                            <p class="mt-2 mb-1">Príjem</p>
                            <h4 class="mt-0">900€ - 3.000€</h4>
                        </div>
                    </div>

                    <!-- schod4 -->
                    <div class="view2__schod__wrap" style="margin-top: 0px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div id="schod4H" class="schodh invisible">
                                <h3>M1</h3>
                                <h6>600j</h6>
                                <p class="mb-1">vlastnej produkcie</p>
                                <h6>1200j</h6>
                                <p>celkovej produkcie<br/>za kvartál, 3 x R2</p>
                            </div>
                            <div class="view2__schod__yellow-circle"></div>
                            <div
                                id="schod4L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod4" class="view2__schod"></div>
                        <div
                            id="schodT4"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>OBCHODNÝ VEDÚCI</h4>
                            <p class="mt-2 mb-1">Príjem</p>
                            <h4 class="mt-0">1.000€ - 4.500€</h4>
                        </div>
                    </div>

                    <!-- schod5 -->
                    <div class="view2__schod__wrap" style="margin-top: -150px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div id="schod5H" class="schodh invisible">
                                <h3>M2</h3>
                                <h6>800j</h6>
                                <p class="mb-1">vlastnej produkcie</p>
                                <h6>3800j</h6>
                                <p>celkovej produkcie<br/>za kvartál, 6 x R2</p>
                            </div>
                            <div class="view2__schod__yellow-circle"></div>
                            <div
                                id="schod5L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod5" class="view2__schod"></div>
                        <div
                            id="schodT5"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>OBLASTNÝ<br/>MANAŽÉR</h4>
                            <p class="mt-2 mb-1">Príjem</p>
                            <h4 class="mt-0">3.000€ - 7.000€</h4>
                        </div>
                    </div>

                    <!-- schod6 -->
                    <div class="view2__schod__wrap" style="margin-top: -300px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div id="schod6H" class="schodh invisible">
                                <h3>M3</h3>
                                <h6>800j</h6>
                                <p class="mb-1">vlastnej produkcie</p>
                                <h6>5500j</h6>
                                <p>celkovej produkcie<br/>za kvartál, 6 x R2</p>
                            </div>
                            <div class="view2__schod__yellow-circle"></div>
                            <div
                                id="schod6L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod6" class="view2__schod"></div>
                        <div
                            id="schodT6"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>OBLASTNÝ<br/>MANAŽÉR<br/>SENIOR</h4>
                            <p class="mt-2 mb-1">Príjem</p>
                            <h4 class="mt-0">4.000€ - 8.500€</h4>
                        </div>
                    </div>

                    <!-- schod7 -->
                    <div class="view2__schod__wrap" style="margin-top: -450px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div id="schod7H" class="view2__black schodh invisible">
                                <h4>D1</h4>
                            </div>
                            <div class="view2__schod__yellow-circle"></div>
                            <div
                                id="schod7L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod7" class="view2__schod"></div>
                        <div
                            id="schodT7"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>OBLASTNÝ<br/>RIADITEĽ</h4>
                            <h6 class="mt-2">1200j</h6>
                            <p class="mb-1">vlastnej produkcie</p>
                            <h6>9000j</h6>
                            <p>celkovej produkcie<br/>za kvartál, 6 x M1</p>

                            <p class="mt-0 mb-1">Príjem</p>
                            <h4>6.000€ - 15.000€</h4>
                        </div>
                    </div>

                    <!-- schod8 -->
                    <div class="view2__schod__wrap invisible" style="margin-top: -600px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div id="schod8H" class="view2__black schodh invisible">
                                <h4>D2</h4>
                            </div>
                            <div class="view2__schod__yellow-circle"></div>
                            <div
                                id="schod8L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod8" class="view2__schod"></div>
                        <div
                            id="schodT8"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>REGIONÁLNY<br/>RIADITEĽ</h4>
                            <h6 class="mt-2">24 000J</h6>

                            <p>za kvartál,<br/>(dva kvartály po sebe)<br/>5 x M2</p>

                            <p class="mt-0 mb-1">Príjem</p>
                            <h4>8.000€ - 20.000€</h4>
                        </div>
                    </div>

                    <!-- schod9 -->
                    <div class="view2__schod__wrap invisible" style="margin-top: -750px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div
                                id="schod9H"
                                class="view2__black schodh invisible"
                                style="background-color: #ffcd5b"
                            >
                                <h4 style="color: #171e28 !important">VP</h4>
                            </div>
                            <div
                                class="view2__schod__yellow-circle"
                                style="background-color: #171e28"
                            ></div>
                            <div
                                id="schod9L"
                                class="view2__schod__yellow-line view2__schod--js"
                            ></div>
                        </div>
                        <div id="schod9" class="view2__schod"></div>
                        <div
                            id="schodT9"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        >
                            <h4>VICEPREZIDENT</h4>
                            <h6 class="mt-2">56 000J</h6>

                            <p>za kvartál,<br/>(dva kvartály po sebe)<br/>4 x D1</p>

                            <p class="mt-0 mb-1">Príjem</p>
                            <h4>20.000€ a viac</h4>
                        </div>
                    </div>

                    <!-- schod10 -->
                    <div class="view2__schod__wrap invisible" style="margin-top: -1000px">
                        <div
                            class="view2__schod__flex d-flex justify-content-center flex-column align-items-center"
                        >
                            <div
                                id="schod10H"
                                class="view2__black schodh invisible"
                                style="background-color: transparent"
                            >
                                <img
                                    alt="logo"
                                    src="../assets/img/logo.svg"
                                    style="width: 127px; margin-bottom: 17px;"
                                />
                            </div>
                        </div>
                        <div id="schod10" class="view2__schod"></div>
                        <div
                            id="schodT10"
                            class="view2__schod__under d-flex flex-column align-items-center invisible"
                        ></div>
                    </div>


                </div>
            </div>
            <!-- <router-link to="/view3"
              ><button class="btn btn-primary">Ďalší krok</button></router-link
            > -->
            <div class="primary-circle">
                <h3 class="mb-0 mt-0 plan">PLÁN<br/>KARIÉRY</h3>
            </div>
            <div class="primary-circle-empty"></div>
            <div class="primary-circle-empty empty2"></div>

            <button @click="clickSchod()" class="btn btn-primary">Ďalší krok</button>
            <!-- <button @click="slideSchody()" class="btn btn-primary">Ďalší krok</button> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'View2',

    data: () => ({
        schod: 1,
    }),

    mounted() {
        this.fadeIn();
    },

    methods: {
        clickSchod() {
            if (this.schod == 7) {
                const schody1 = document.getElementById('schody1');
                const schody2 = document.querySelectorAll(
                    '.view2__schod__wrap.invisible',
                );
                schody1.classList.add('slide');
                schody2.forEach((x) => x.classList.remove('invisible'));
            } else if (this.schod == 10) {
                let sch9 = document.getElementById('schod10H');
                let sch99 = document.getElementById('schod10');
                let sch999 = document.getElementById('schodT10');
                sch999.classList.remove('invisible');
                sch99.classList.add('active');
                sch9.classList.remove('invisible');
                this.schod++;
                return;
            } else if (this.schod == 11) {
                this.$router.push({name: 'View3'});
            }

            let sch1 = document.getElementById('schod' + this.schod);
            let sch2 = document.getElementById('schodT' + this.schod);
            let sch3 = document.getElementById('schod' + this.schod + 'L');
            let sch4 = document.getElementById('schod' + this.schod + 'H');

            sch1.classList.add('active');
            sch2.classList.remove('invisible');
            sch3.classList.add('active');
            sch4.classList.remove('invisible');
            this.schod++;
        },
    },
};
</script>

<style lang="scss">
#view2__inner {
    overflow: hidden;
    background-color: #6b97a6;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;

    @media (min-width: 1581px) {
        width: 100%;
        height: 100vh;
    }
}

#view__view2 {
    width: 100%;
    height: 1024px;
    display: flex;
    justify-content: center;
    background-color: #6b97a6;

    .primary-circle {
        position: absolute;
        left: 50%;
        top: 550px;
        margin-left: 277px;
        z-index: 3;
        padding: 40px;
        height: 158px;
    }

    .primary-circle-empty {
        position: absolute;
        background-color: transparent;
        left: 50%;
        top: 545px;
        margin-left: 250px;
        padding: 33px;
        height: 159px;
        border: 2px solid;
        width: 154px;
        border-radius: 50%;
        z-index: 1;

        &.empty2 {
            top: 580px;
            margin-left: 268px;
        }
    }

    button {
        position: absolute;
        top: 803px;
        left: 50%;
        margin-left: -120px;
        z-index: 3;
    }

    h3 {
        font-size: 26px;
        color: $dark;
        font-family: $gotham-medium;
        letter-spacing: 2px;
        margin-bottom: 8px;

        &.plan {
            font-family: $chronic-black;
            letter-spacing: 6px;
        }
    }

    h4 {
        font-size: 15px;
        color: $primary;
        font-family: $gotham-medium;
        letter-spacing: 2px;
        margin: 3px;

        &:last-child {
            font-size: 16px;
            font-family: $gotham-medium;
            letter-spacing: 2px;
            color: #fff;
        }
    }

    h6 {
        font-size: 19px;
        font-family: $gotham-medium;
        letter-spacing: 2px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
    }

    p {
        font-size: 14px;
        color: #fff;
        margin-top: 1px;
        margin-bottom: 26px;
        white-space: nowrap;
    }

    .winners-logo {
        height: 89px;
        width: auto;
    }
}

.view2__center-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 14px;
    position: absolute;
}

.view2__schod {
    width: 100%;
    height: 27px;
    background: #96adba;
    box-shadow: 0px -0.299408px 0.0901259px rgba(0, 0, 0, 0.123704);

    &.active {
        height: 27px;
        background: #96adba;
        box-shadow: 0px 47px 72px rgba(0, 0, 0, 0.44),
        0px 18.2319px 22.1627px rgba(0, 0, 0, 0.316296),
        0px 7.72955px 9.03213px rgba(0, 0, 0, 0.262287),
        0px 2.74581px 3.59263px rgba(0, 0, 0, 0.22),
        0px 0.435713px 1.08573px rgba(0, 0, 0, 0.177713),
        0px -0.299408px 0.0901259px rgba(0, 0, 0, 0.123704);
        transition: 1s;
    }
}

.row-schody {
    height: 600px;
    align-items: center;
    margin-top: 72px;
}

.view2__schod {
    z-index: 2;
    position: relative;
}

.view2__schod__wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 107px;
}

.view2__schod__flex {
    position: absolute;
    bottom: 0;
}

.view2__schod__yellow-circle {
    background: $primary;
    //   padding: 1px;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    z-index: 1;
}

.view2__schod--js {
    height: 26px !important;
    transition: 1.1s;
    transition-timing-function: ease;

    &.active {
        height: 115px !important;
    }
}

.view2__schod__yellow-line {
    height: 115px;
    background: $primary;
    width: 3px;
    z-index: 1;

    &.line1 {
        position: absolute;
        top: -12px;
        left: 50%;
        margin-left: -500px;
        transform: rotate(323deg);
    }

    &.line2 {
        position: absolute;
        top: -30px;
        left: 50%;
        margin-left: 388px;
        transform: rotate(25deg);
    }

    &.line3 {
        position: absolute;
        top: 408px;
        left: 0%;
        margin-left: -17px;
        transform: rotate(65deg);
    }

    &.line4 {
        position: absolute;
        top: 737px;
        left: 50%;
        margin-left: -469px;
        transform: rotate(47deg);
    }

    &.line5 {
        position: absolute;
        top: 677px;
        left: 50%;
        margin-left: 509px;
        transform: rotate(47deg);
        height: 158px;
    }

    &.line6 {
        position: absolute;
        top: 332px;
        right: 64px;
        margin-left: 388px;
        transform: rotate(296deg);
        height: 150px;
    }
}

.view2__schod__under {
    position: absolute;
    bottom: -127px;
    height: 100px;
    z-index: 3;
    opacity: 1;
    transition: 1s;
    width: 152px;
}

.view2__black {
    background: #171e28;
    padding: 24px;
    border-radius: 50%;
    //   width: 23px;
    //   height: 23px;
    z-index: 0;
    margin-bottom: -10px;

    h4 {
        font-size: 22px !important;
        color: $primary !important;
        font-family: $font-black !important;
    }
}

.schodh {
    opacity: 1;
    transition: 1s;
}

#schody1 {
    position: absolute;
    left: 50%;
    margin-left: -545px;
    width: 1508px;
    max-width: 1508px;
    top: 100px;
    transition: 1s;
    z-index: 2;

    &.slide {
        top: 328px;
        margin-left: -1076px;
    }
}

// @keyframes schod1 {
//   from {
//     background-color: red;
//   }
//   to {
//     background-color: yellow;
//   }
// }

// /* The element to apply the animation to */
// div {
//   width: 100px;
//   height: 100px;
//   background-color: red;
//   animation-name: example;
//   animation-duration: 4s;
// }
</style>
